$( document ).ready(function() {


    $("#formbuilder_le_eeg_kontakt").on("submit", function (e) {
        
        e.preventDefault(); // avoid to execute the actual submit of the form.

        var form = $(this);
        var url = form.attr('action');
        
        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), 
            success : function(text){
                formSuccessVA();
            }
        });

    });

    function formSuccessVA(){
        
        var elem = $("#container-form");
        elem.height(400);
        
        elem.fadeOut( "slow", function() {
            elem.html('<div class="d-flex justify-content-center align-items-center bg-green pt-5 text-center dinblack font__size-40 font__color-white text-center"><p class="p-5">Vielen Dank für Deine Anmeldung zum Newsletter!<br />Wir halten Dich auf dem Laufenden.</p></div>');
            elem.fadeIn("slow");
        });

    }

});    